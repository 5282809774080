import React from "react";
import axios from "axios";

import { useState } from 'react';
import ReactDOM from 'react-dom/client';

import {  GoogleReCaptchaProvider,  GoogleReCaptcha} from 'react-google-recaptcha-v3';


function App() 
{
    const [{loading, error, success}, setState] = useState({});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");

  const submitForm = (event) => {
    event.preventDefault();

    var data = JSON.stringify({"name":name,"number":"123456789","details":"signup","email":email,"newsletter":1});

    var config = {
        method: 'post',
        url: 'https://api.belove.au/contact',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
    };
    setState({loading:true})

    axios(config).then(function (response) {
        console.log(response);
        if(response.data && response.data.success)
        {
            setState({success:true})
        }
        else
        {
            setState({error:true})
        }
    })
    .catch(function (error) {
        console.log(error);
        setState({error:true})
    });

  }

  return (<main class="main page-404">
  <div class="container">
      <div class="row align-items-center height-100vh text-center">
          <div class="col-lg-8 m-auto">
              <p class="mb-50"><img src="/images/logo.png" alt="Belove" class="hover-up"/></p>
              <h2 class="mb-30">Coming Soon</h2>
              


              { loading ?
                    <div class="text-center">
                        <h5 class="mb-5">Loading</h5>
                        <div class="loader" style={{margin: "auto"}}>
                            <div class="bar bar1"></div>
                            <div class="bar bar2"></div>
                            <div class="bar bar3"></div>
                        </div>
                    </div>
                    : 
                    null
                }

                

                { success ?
                    <div class="text-center">
                        <h5 class="mb-5">Thank you</h5>
                        <p class="font-lg text-grey-700 mb-30">Will hear from us soon.</p>                        
                    </div>
                    : 
                    null
                }

                

                { error ?
                    <div class="text-center">
                        <h5 class="mb-5">Error</h5>
                        <p class="font-lg text-grey-700 mb-30">Something went wrong, please try again later.</p>                        
                    </div>
                    : 
                    null
                }

                
                { !loading && !error && !success ?
                    <>
                        <p class="font-lg text-grey-700 mb-30">
                            Get updates by submitting your name and email below. 
                        </p>
                        <form class="contact-form-style text-center" id="contact-form" onSubmit={submitForm}>
                            <div class="row">
                                <div class="col-lg-6 m-auto">
                                    <div class="input-style mb-20 hover-up">
                                        <input required name="name" placeholder="name" onChange={(e) => setName(e.target.value)} type="text"/>
                                    </div>
                                    <div class="input-style mb-20 hover-up">
                                        <input required name="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} type="email"/>
                                    </div>
                                    { !token ? <GoogleReCaptcha onVerify={  (GoogleReCaptchaToken) => {setToken(GoogleReCaptchaToken)  }} /> : null}
            
                                </div>
                            </div>
                            <input type="submit" class="btn btn-default font-xs hover-up" style={{width:"150px"}}/>
                        </form> 
                    </>
                    : 
                    null
                }


          </div>
      </div>
  </div>
</main>
  );
}

export default App;
