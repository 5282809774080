/*import 'core-js/actual'; // <- at the top of your entry point
import "regenerator-runtime/runtime.js";*/
//import './assets/css/main.css?v=3.4';
import { Provider,useSelector } from 'react-redux'
//import firebase from 'firebase/app'
import {  GoogleReCaptchaProvider,  GoogleReCaptcha} from 'react-google-recaptcha-v3';



  /*
ReactDOM.render(
    <Provider>
            <GoogleReCaptchaProvider reCaptchaKey="6Ldt0FkdAAAAAJ7wk1tteGK4IxMlTS9CXJhUD3DT">
                <App />
            </GoogleReCaptchaProvider>
    </Provider>, document.getElementById('root')
);*/
  


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
                  <GoogleReCaptchaProvider reCaptchaKey="6LfkFnIjAAAAAGSe8J_oRTPUFKKr_vO7CHRpfkQw">

    <App />
            </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
